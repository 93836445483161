<template>
  <div class="team-section-container" id="teamSectionContainer">
    <div class="content">
      <div id="leadership">
        <div class="tab tab-1">
          <h3
            class="toggle-heading mb-md text-white relative w-screen md:w-full -left-[15px] rounded-none md:rounded-base md:-left-0"
          >
            {{ props.data.team_data.tab_title }}
          </h3>
        </div>
        <div
          class="team-groups px-0 lg:px-4"
          v-for="(group, index) of props.data.team_data.team_groups"
          :key="`team-group-${index}`"
        >
          <h3 class="mb-4">{{ group.team_section_name }}</h3>
          <p
            v-if="group.team_section_description"
            class="max-w-full lg:max-w-[60%]"
          >
            {{ group.team_section_description }}
          </p>
          <div class="team-cards">
            <div
              class="team-card"
              v-for="(teammate, indx) of group.team_members"
              :key="`teammate-${indx}`"
            >
              <router-link :to="`/team/${teammate.slug}`">
                <div class="team-image">
                  <div
                    class="image-container"
                    :style="{
                      backgroundImage: teammate.featured_image
                        ? `url(${teammate.featured_image})`
                        : `url(${defaultImg})`,
                    }"
                  />
                </div>
              </router-link>
              <div class="team-info">
                <h4
                  class="name"
                  v-html="
                    `${teammate.post_title}${
                      teammate.acf.title ? '</br> ' + teammate.acf.title : ''
                    }`
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="drugs" v-if="props.data.show_section">
        <div class="tab tab-2">
          <h3
            class="toggle-heading mb-md text-white relative w-screen md:w-full -left-[15px] rounded-none md:rounded-base md:-left-0"
          >
            {{ props.data.drug_data.tab_title }}
          </h3>
        </div>
        <h3 class="subtitle px-0 lg:px-4">
          {{ props.data.drug_data.section_subtitle }}
        </h3>
        <div class="drugs-table px-0 lg:px-4">
          <div class="table-headings">
            <div class="heading-drug-company">
              <div
                class="title"
                v-html="props.data.drug_data.title_for_the_table.drug"
              ></div>
            </div>
            <div class="hidden lg:flex heading-mod-dev">
              <div class="heading-mod">
                <div
                  class="title"
                  v-html="props.data.drug_data.title_for_the_table.modality"
                ></div>
              </div>
              <div class="heading-dev">
                <div
                  class="title"
                  v-html="props.data.drug_data.title_for_the_table.stage"
                ></div>
              </div>
            </div>
            <div class="flex lg:hidden heading-mod-dev-mobile">
              <div class="heading-combined-mobile">
                <div
                  class="title"
                  v-html="props.data.drug_data.title_for_the_table.stage"
                ></div>
              </div>
            </div>
          </div>
          <div class="table-content">
            <div
              class="table-entry"
              v-for="(drug, idx) of props.data.drug_data.drug_data"
              :key="`drug-data-${idx}`"
            >
              <div class="drug-company" v-html="drug.drug_target_company" />
              <div class="mod-dev">
                <div
                  class="modality"
                  :style="{
                    backgroundColor: drug.modality.modality_color,
                    color:
                      drug.modality.modality_color === '#FFE500'
                        ? 'black'
                        : 'white',
                  }"
                >
                  {{ drug.modality.modality_text }}
                </div>
                <div
                  class="dev-stage"
                  :style="{
                    backgroundColor: drug.latest_dev_stage.dev_stage_color,
                    color:
                      drug.latest_dev_stage.dev_stage_color === '#FFE500'
                        ? 'black'
                        : 'white',
                  }"
                >
                  {{ drug.latest_dev_stage.dev_stage_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else id="drugs"></div>
    </div>
  </div>
</template>
<script setup>
// import { onMounted } from 'vue'
import defaultImg from '@/assets/img/default-img.jpg'
// import { useRouter } from 'vue-router'
import { gsap } from 'gsap'
// import { ScrollSmoother } from '@/assets/js/ScrollSmoother.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

// const router = useRouter()
// const currentTab = ref(0)
const props = defineProps(['data'])
// const smoother = ScrollSmoother.get()

// console.log(props.data)

// const setCurrentTab = (_data, hash) => {
//   // currentTab.value = _data

//   if (hash) {
//     toSection(hash)
//   }
// }

// const toSection = (selector) => {
//   const elem = document.querySelector(`${selector}`)
//   smoother.scrollTo(elem, true, 'top 450px')
// }

// onMounted(() => {
//   if (router.currentRoute.value.hash === '#leadership') {
//     setCurrentTab(0, '#leadership')
//   } else if (router.currentRoute.value.hash === '#drugs') {
//     setCurrentTab(1, '#drugs')
//   }
// })
</script>
<style lang="scss" scoped>
.team-section-container {
  @apply container mb-lg;
  .tabs {
    @apply flex flex-col lg:flex-row gap-x-xs mb-lg;
    .tab {
      @apply w-full lg:w-[49.8%] mb-xs lg:mb-0;
      .toggle-heading {
        @apply text-center cursor-pointer;
        &.inactive {
          @apply text-curie-blue/50 bg-white transition-colors duration-150 ease-out;
          &:hover {
            @apply text-curie-blue/80 bg-white;
          }
        }
      }
    }
  }
  .content {
    .subtitle {
      @apply text-curie-blue mb-md;
    }
    .team-groups {
      .team-cards {
        @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 items-stretch mb-lg;
        .team-card {
          @apply col-span-1;
          .team-image {
            @apply w-full h-[400px] rounded-base overflow-hidden cursor-pointer;
            .image-container {
              @apply w-full h-full bg-cover bg-no-repeat bg-center  transition-transform duration-300 ease-in-out;
            }
            &:hover .image-container {
              @apply transform scale-110;
            }
          }
          .team-info {
            @apply mt-sm pb-sm;
            h4 {
              @apply pb-xs text-[20px] xl:text-[22px] leading-[26px] xl:leading-[28px];
            }
          }
        }
      }
    }
    .drugs-table {
      .table-headings {
        @apply flex flex-row relative w-screen md:w-full -left-[15px] md:-left-0;
        .heading-drug-company {
          @apply w-6/12 lg:w-5/12;
          .title {
            @apply text-curie-med-gray pl-6 lg:pl-0 text-lg font-FKGrotesk leading-6 font-normal;
            margin-bottom: 22px;
          }
        }
        .heading-mod-dev {
          @apply flex-row w-6/12 lg:w-7/12;
          .heading-mod {
            @apply w-6/12 ml-sm;
            .title {
              @apply text-curie-med-gray text-lg font-FKGrotesk leading-6 font-normal;
              margin-bottom: 22px;
            }
          }
          .heading-dev {
            @apply w-6/12 ml-sm;
            .title {
              @apply text-curie-med-gray pl-6 lg:pl-0 text-lg font-FKGrotesk leading-6 font-normal;
              margin-bottom: 22px;
            }
          }
        }
        .heading-mod-dev-mobile {
          .title {
            @apply text-curie-med-gray pl-6 lg:pl-0 text-lg font-FKGrotesk leading-6 font-normal;
            margin-bottom: 22px;
          }
        }
      }
      .table-content {
        @apply flex flex-col gap-y-[1px] lg:gap-y-xs relative w-screen md:w-full -left-[15px] md:-left-0;
        .table-entry {
          @apply flex flex-row gap-x-[4px] transition-transform duration-300 ease-in-out;
          &:hover {
            @apply transform scale-[1.01];
          }
          .drug-company {
            @apply w-6/12 lg:w-5/12 lg:bg-white flex items-center p-sm rounded-none lg:rounded-base;
          }
          .mod-dev {
            @apply flex flex-col lg:flex-row w-6/12 lg:w-7/12 gap-x-[8px] gap-y-[1px] lg:gap-y-0 min-h-[80px];
            .modality {
              @apply w-full lg:w-6/12 p-sm items-center rounded-none lg:rounded-base hidden lg:flex;
            }
            .dev-stage {
              @apply w-full lg:w-6/12 p-sm flex items-center rounded-none lg:rounded-base h-full;
            }
          }
        }
      }
    }
  }
}
</style>
